import { useCallback, useEffect, useState } from 'react';

import { Input, Modal, Spin, Tag, Typography } from 'antd';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { getCurrentUser } from '../../../store/features/users/usersSlice';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import ChangePasswordForm from '../../containers/forms/ChangePasswordForm/ChangePasswordForm';
import Button from '../../elements/Button/Button';
import ViewWrapper from '../../elements/ViewWrapper';

import './AccountSettingsView.less';

const { Text, Title } = Typography;

interface ParamsState {
  id: string;
}

const AccountSettingsView = () => {
  const { id } = useParams<ParamsState>();
  const dispatch = useAppDispatch();
  const { currentUser, fetchingUser } = useAppSelector((state) => state.users);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  console.log('id ', id);

  const fetchCurrentUser = useCallback(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    fetchCurrentUser();
  }, [dispatch, fetchCurrentUser]);

  return (
    <ViewWrapper>
      {fetchingUser ? (
        <div className="card-container">
          <Spin />
        </div>
      ) : (
        <div className="card-container">
          <div className="card">
            <Title level={5}>User Information</Title>
            <Text type="secondary" className="head">
              Username
            </Text>
            <Input disabled value={currentUser?.name} />
            <Text type="secondary" className="head">
              Email
            </Text>
            <Input disabled value={currentUser?.email} />
            <Text type="secondary" className="head">
              Client
            </Text>
            <Input disabled value="Hershey company" />
            <Text type="secondary" className="head">
              Role
            </Text>
            <div>
              {_.uniqBy(currentUser?.roles, 'display_name').map(
                (p: { display_name: string }) => {
                  if (!!p.display_name) {
                    return <Tag color="purple">{p.display_name}</Tag>;
                  }
                  return null;
                }
              )}
            </div>
            <Text type="secondary" className="head">
              Password
            </Text>
            <Input type="password" disabled value="****************" />
            <Button
              type="link"
              className="link-button"
              onClick={() => setChangePasswordModalOpen(true)}
              style={{ alignSelf: 'flex-start' }}
            >
              Change Password
            </Button>
          </div>
          <Modal
            key={currentUser?.uuid}
            open={changePasswordModalOpen}
            title={'Change Password'}
            footer={null}
            onCancel={() => {
              setChangePasswordModalOpen(false);
            }}
          >
            <ChangePasswordForm userId={currentUser?.uuid} />
          </Modal>
        </div>
      )}
    </ViewWrapper>
  );
};

export default AccountSettingsView;
