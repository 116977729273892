import { useCallback, useEffect, useMemo, useState } from 'react';

import { CheckOutlined, LineOutlined } from '@ant-design/icons';
import { Input, message, Space, Tag, Typography } from 'antd';
import { History } from 'history';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from '../../../store';
import Button from '../../elements/Button/Button';
import ViewWrapper from '../../elements/ViewWrapper';

import { useParams } from 'react-router-dom';
import {
  getApiLogs,
  loadMoreApiLogs,
} from '../../../store/features/api-log/apiLogsSlice';
import { fetchClients } from '../../../store/features/clients/clientsSlice';
import { getUser, updateUser } from '../../../store/features/users/usersSlice';
import { isUserAuthorized, UserRoles } from '../../../util';
import { DrawerHashRoute } from '../../containers/Drawers/types';
import './UserDetailsView.less';
import ApiLogTableList from './components/ApiLogTableList/ApiLogTableList';

const { Text, Title } = Typography;

interface UserDetailsViewProps {
  history: History;
}

interface PasswordRequirement {
  text: string;
  fulfilled: boolean;
}

const UserDetailsView = ({ history }: UserDetailsViewProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { user, fetchingUser } = useAppSelector((state) => state.users);
  const { clients } = useAppSelector((state) => state.clients);

  const {
    value: apiLogs,
    fetchingApiLogs,
    pagination,
    totalCount,
  } = useAppSelector((state) => state.apiLogs);

  const hasUsers = !(!fetchingApiLogs && apiLogs.length === 0);
  const hasMoreUsers =
    hasUsers && pagination?.offset! + pagination?.offset! < totalCount!;
  const isPermittedToLoadMore = !fetchingApiLogs && hasMoreUsers;

  const hasPermission = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.admin,
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
    ]);
  }, []);

  const canChangePassword = useMemo((): boolean => {
    return isUserAuthorized([
      UserRoles.employeeDeveloper,
      UserRoles.employeeModeler,
      UserRoles.employeeClientServices,
      UserRoles.employeeLibrarian,
      UserRoles.employee,
    ]);
  }, []);

  const getClientName = useCallback(
    (clientId: string): string => {
      const client = clients.find((c) => c.uuid === clientId);
      return client?.name || '';
    },
    [clients]
  );

  const renderClientName = (clientId?: string) => {
    if (!clientId) {
      return <Input disabled value="" />;
    }
    return <Input disabled value={getClientName(clientId)} />;
  };

  const headerSectionRight = useMemo(
    (): JSX.Element => (
      <Button
        type="primary"
        onClick={() =>
          history.push({
            hash: DrawerHashRoute.UserForm,
            state: { data: user },
          })
        }
      >
        Edit User
      </Button>
    ),
    [history, user]
  );

  useEffect(() => {
    if (user?.uuid) {
      dispatch(getApiLogs(user?.uuid));
    }
  }, [dispatch, user?.uuid]);

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (hasPermission) {
      dispatch(fetchClients({ query: '' }));
    }
  }, [dispatch, hasPermission]);

  const [newPassword, setNewPassword] = useState('');
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordRequirement[]
  >([
    { text: 'Minimum 10 characters', fulfilled: false },
    { text: 'Special characters (!, @, #, $, %, ^, &, *)', fulfilled: false },
    { text: 'Numbers (0-9)', fulfilled: false },
    { text: 'Uppercase letters (A-Z)', fulfilled: false },
    { text: 'Lowercase letters (a-z)', fulfilled: false },
  ]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fulfilledRequirements = passwordRequirements.map((req) =>
      req.text === 'Minimum 10 characters'
        ? { ...req, fulfilled: value.length >= 10 }
        : req.text === 'Special characters (!, @, #, $, %, ^, &, *)'
        ? { ...req, fulfilled: /[!@#$%^&*]/.test(value) }
        : req.text === 'Numbers (0-9)'
        ? { ...req, fulfilled: /\d/.test(value) }
        : req.text === 'Uppercase letters (A-Z)'
        ? { ...req, fulfilled: /[A-Z]/.test(value) }
        : req.text === 'Lowercase letters (a-z)'
        ? { ...req, fulfilled: /[a-z]/.test(value) }
        : req
    );
    setPasswordRequirements(fulfilledRequirements);
  };

  const handlePasswordSave = async () => {
    if (newPassword.length < 10) {
      message.error('Password must be at least 10 characters long.');
      return;
    }

    const characterTypeRequirements = passwordRequirements.slice(1);
    const numFulfilledCharacterTypes = characterTypeRequirements.filter(
      (req) => req.fulfilled
    ).length;

    if (numFulfilledCharacterTypes < 3) {
      message.error(
        'Password must contain at least 3 of the following: uppercase letters, lowercase letters, numbers, or special characters.'
      );
      return;
    }

    try {
      await dispatch(
        updateUser({
          userId: user?.uuid!,
          user: { ...user!, password: newPassword },
          userRoles: user?.roles?.map((r) => r.name) || [],
        })
      );

      message.success('Password updated successfully');
      setShowPasswordChange(false);
      setNewPassword('');
      setPasswordRequirements(
        passwordRequirements.map((req) => ({ ...req, fulfilled: false }))
      );
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  return (
    <ViewWrapper
      options={{
        goBack: true,
        loading: fetchingUser,
      }}
      headerTitle={user?.name ? user?.name : 'User'}
      headerSectionRight={headerSectionRight}
    >
      <div className="card-container">
        <div className="card">
          <Title level={5}>{user?.name}</Title>
          <Text type="secondary" className="head">
            Username
          </Text>
          <Input disabled value={user?.name} />
          <Text type="secondary" className="head">
            Email
          </Text>
          <Input disabled value={user?.email} />
          <Text type="secondary" className="head">
            Client
          </Text>
          {renderClientName(user?.client_id || '')}
          <Text type="secondary" className="head">
            Role
          </Text>
          <div>
            {_.uniqBy(user?.roles, 'display_name').map(
              (p: { display_name: string }) => {
                if (!!p.display_name) {
                  return <Tag color="purple">{p.display_name}</Tag>;
                }
                return null;
              }
            )}
          </div>

          {canChangePassword ? (
            !showPasswordChange ? (
              <>
                <Text type="secondary" className="head">
                  Password
                </Text>
                <Button
                  onClick={() => setShowPasswordChange(true)}
                  style={{ width: '210px' }}
                >
                  Change Password
                </Button>
              </>
            ) : (
              <Space direction="vertical" style={{ width: '100%' }}>
                <Input.Password
                  value={newPassword}
                  style={{ width: '210px' }}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    handlePasswordChange(e);
                  }}
                  placeholder="Enter new password"
                />
                <div style={{ width: 'fit-content' }}>
                  <ul className="password-requirements-list">
                    {passwordRequirements.map((req, index) => (
                      <li
                        key={index}
                        style={{ color: req.fulfilled ? '#40C976' : 'inherit' }}
                      >
                        {req.fulfilled ? (
                          <CheckOutlined
                            style={{ color: '#40C976', marginRight: 8 }}
                          />
                        ) : (
                          <LineOutlined style={{ marginRight: 8 }} />
                        )}
                        {req.text}
                      </li>
                    ))}
                  </ul>
                </div>
                <Space>
                  <Button
                    type="primary"
                    onClick={handlePasswordSave}
                    disabled={!newPassword}
                  >
                    Save Password
                  </Button>
                  <Button
                    onClick={() => {
                      setShowPasswordChange(false);
                      setNewPassword('');
                      setPasswordRequirements(
                        passwordRequirements.map((req) => ({
                          ...req,
                          fulfilled: false,
                        }))
                      );
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Space>
            )
          ) : null}
        </div>
        <div className="card">
          <Title level={5}>Activity</Title>
          <ApiLogTableList
            totalCount={totalCount}
            loading={fetchingApiLogs}
            apiLogs={apiLogs}
            onLoadMore={() => dispatch(loadMoreApiLogs(user?.uuid))}
            hasMore={isPermittedToLoadMore}
          />
        </div>
      </div>
    </ViewWrapper>
  );
};

export default UserDetailsView;
