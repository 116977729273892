import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, handleError, PaginationModel } from '../../../api/base';
import ProductLibraryService from '../../../api/product-library';
import ProductListService from '../../../api/product-list';
import { ListProductLibraryParams } from '../../../hooks/product-library';
import {
  ListProductLibraryModel,
  ProductFieldsModel,
} from '../../../models/product-library';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

interface ProductListError {
  code: number | null;
  message: string | null;
}

interface BulkUpdateProductsProp {
  uuids?: string[];
  brand_id?: string;
  categories_set?: string[];
  categories_delete?: string[];
  market?: number;
  format?: number;
  count?: string;
  size?: string;
  tags_delete?: string[];
  tags_set?: string[];
  fields?: ProductFieldsModel;
}

export interface ProductListState {
  value: ListProductLibraryModel[];
  product: ListProductLibraryModel | {};
  addingProducts: string[];
  removingProducts: string[];
  selectedProducts: ListProductLibraryModel[];
  allProductListItems: ListProductLibraryModel[];
  fetchingProductListItems: boolean;
  searchingProductList: boolean;
  savingProductList: boolean;
  deletingProduct: boolean;
  count: number;
  totalCount: number;
  error: ProductListError;
  pagination: PaginationModel | null;
}

const initialState: ProductListState = {
  value: [],
  product: {},
  addingProducts: [],
  removingProducts: [],
  selectedProducts: [],
  allProductListItems: [],
  fetchingProductListItems: false,
  searchingProductList: false,
  savingProductList: false,
  deletingProduct: false,
  count: 0,
  totalCount: 0,
  error: { code: null, message: null },
  pagination: null,
};

export const fetchProductList = createAsyncThunk(
  'productList/fetchProductList',
  async (
    {
      params,
      searching = false,
      loadMore = false,
    }: {
      params: ListProductLibraryParams;
      searching?: boolean;
      loadMore?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await ProductListService().listProductList(queryString);
      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
        loadMore,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchProductsByName = createAsyncThunk(
  'productList/searchProductsByName',
  async (query: string, { getState, rejectWithValue }) => {
    try {
      const {
        productList: { pagination },
        productLibrary: { pageSize, currentPage },
      } = getState() as RootState;

      let params: ListProductLibraryParams = {
        _limit: pageSize || pagination?.limit || 10,
        _offset: currentPage ? (currentPage - 1) * (pageSize || 10) : 0,
        _order_by: 'updated_at:desc',
      };

      if (query && query.length) {
        params = {
          ...params,
          q: `${query.toLowerCase()}`,
        };
      }

      const queryString = convertParamsToQuery(params);
      const resp = await ProductListService().searchProductList(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const filterProducts = createAsyncThunk(
  'productList/filterProducts',
  async (params: ListProductLibraryParams, { rejectWithValue }) => {
    try {
      const queryString = convertParamsToQuery(params);

      const resp = await ProductListService().searchProductList(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        loadMore: false,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paginateProductList = createAsyncThunk(
  'productList/paginateProductList',
  async (
    {
      page,
      pageSize,
      queryStr,
    }: {
      page: number;
      pageSize: number;
      queryStr: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const limit = pageSize;
      const nextOffset = (page - 1) * pageSize;

      let nextParams: ListProductLibraryParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      if (queryStr && queryStr.length) {
        nextParams = {
          ...nextParams,
          q: `${queryStr.toLowerCase()}`,
        };
      }

      const query = convertParamsToQuery(nextParams);
      const resp = await ProductListService().listProductList(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreProductListItems = createAsyncThunk(
  'productList/loadMoreProductListItems',
  async (
    { hasMoreProductListItems }: { hasMoreProductListItems?: boolean },
    { getState, rejectWithValue }
  ) => {
    try {
      if (!hasMoreProductListItems) return;

      const {
        productList: { pagination },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams = {
        _limit: limit,
        _offset: nextOffset,
        _order_by: 'updated_at:desc',
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await ProductListService().listProductList(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProductsToProductList = createAsyncThunk(
  'productList/addProductsToProductList',
  async (productIds: string[], { rejectWithValue }) => {
    try {
      const resp = await ProductListService().bulkAddProductItemsToList(
        productIds
      );
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProductItemToList = createAsyncThunk(
  'productList/addProductItemToList',
  async (product: ListProductLibraryModel, { rejectWithValue }) => {
    try {
      const resp = await ProductListService().addProductItemToList(
        product.uuid
      );

      const { in_list, count } = resp?.data;

      return {
        uuid: product.uuid,
        in_list,
        count,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// todo: remove and use the removeProductsFromList instead
export const removeSelectedProductItemsFromList = createAsyncThunk(
  'productList/removeSelectedProductItemsFromList',
  async (
    productItems: ListProductLibraryModel[],
    { dispatch, rejectWithValue }
  ) => {
    try {
      const responses = await Promise.all(
        productItems.map(async (productItem) => {
          const resp = await ProductListService().removeProductItemFromList(
            productItem.uuid
          );
          const { count } = resp.data;
          dispatch(searchProductsByName(''));
          return { count };
        })
      );
      const highestCount = Math.min(...responses.map((resp) => resp.count));
      return { count: highestCount };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeProductItemFromList = createAsyncThunk(
  'productList/removeProductItemFromList',
  async (productId: string, { rejectWithValue }) => {
    try {
      const resp = await ProductListService().removeProductItemFromList(
        productId
      );
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProductListCount = createAsyncThunk(
  'productList/fetchProductListCount',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await ProductListService().countProductItemsInList();
      return resp.data.count;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProduct = createAsyncThunk(
  'productList/fetchProduct',
  async (productId: string, { rejectWithValue }) => {
    try {
      const resp = await ProductListService().getProduct(productId);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'productList/deleteProduct',
  async (productId: string, { rejectWithValue }) => {
    try {
      const resp = await ProductListService().deleteProduct(productId);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bulkUpdateProducts = createAsyncThunk(
  'productList/bulkUpdateProducts',
  async (
    updatedInformation: BulkUpdateProductsProp,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const resp = await ProductLibraryService().bulkEdit(updatedInformation);
      dispatch(searchProductsByName(''));
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeProductsFromProductList = createAsyncThunk(
  'productList/removeProductsFromProductList',
  async (productIds: string[], { rejectWithValue }) => {
    try {
      const resp = await ProductListService().bulkRemoveProductItemsFromList(
        productIds
      );
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    startAddingProduct: (state, action) => {
      state.addingProducts.push(action.payload);
    },
    finishAddingProduct: (state, action) => {
      state.addingProducts = state.addingProducts.filter(
        (uuid) => uuid !== action.payload
      );
    },
    startRemovingProduct: (state, action) => {
      state.removingProducts.push(action.payload);
    },
    finishRemovingProduct: (state, action) => {
      state.removingProducts = state.removingProducts.filter(
        (uuid) => uuid !== action.payload
      );
    },
    setSelectedProducts: (
      state,
      action: PayloadAction<ListProductLibraryModel[]>
    ) => {
      state.selectedProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.count = action.payload.pagination.count;
        state.fetchingProductListItems = false;
        state.value = action.payload.data;
        state.allProductListItems = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchProductList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(searchProductsByName.pending, (state) => {
        state.fetchingProductListItems = true;
        state.searchingProductList = true;
      })
      .addCase(searchProductsByName.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.count = action.payload.pagination.count;
        state.fetchingProductListItems = false;
        state.searchingProductList = false;
        state.value = action.payload.data;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchProductsByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
        state.searchingProductList = false;
      });

    builder
      .addCase(filterProducts.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(filterProducts.fulfilled, (state, action) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingProductListItems = false;
        state.pagination = action.payload.pagination;
        state.allProductListItems = action.payload.data;
        state.value = action.payload.data;
      })
      .addCase(filterProducts.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(loadMoreProductListItems.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(loadMoreProductListItems.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...state.value, ...action.payload.data];
          state.fetchingProductListItems = false;
        }
      })
      .addCase(loadMoreProductListItems.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(paginateProductList.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        if (action.payload) {
          state.pagination = action.payload.pagination;
          state.value = [...action.payload.data];
          state.fetchingProductListItems = false;
        }
      })
      .addCase(paginateProductList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(paginateProductList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(addProductsToProductList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(addProductsToProductList.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        state.count = action.payload.count;
      })
      .addCase(addProductsToProductList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(addProductItemToList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(addProductItemToList.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        state.count = action?.payload?.count;
      })
      .addCase(addProductItemToList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(removeSelectedProductItemsFromList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(
        removeSelectedProductItemsFromList.fulfilled,
        (state, action) => {
          state.fetchingProductListItems = false;
          state.count = action.payload.count;
        }
      )
      .addCase(removeSelectedProductItemsFromList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(removeProductItemFromList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(removeProductItemFromList.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        state.count = action.payload.count;
      })
      .addCase(removeProductItemFromList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(fetchProductListCount.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(fetchProductListCount.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        state.count = action.payload;
      })
      .addCase(fetchProductListCount.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });

    builder
      .addCase(fetchProduct.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        state.product = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.fetchingProductListItems = false;
      });

    builder
      .addCase(deleteProduct.pending, (state) => {
        state.deletingProduct = true;
        state.product = {};
      })
      .addCase(deleteProduct.fulfilled, (state) => {
        state.deletingProduct = false;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.fetchingProductListItems = false;
      });

    builder
      .addCase(bulkUpdateProducts.pending, (state) => {
        state.savingProductList = true;
      })
      .addCase(bulkUpdateProducts.fulfilled, (state) => {
        state.savingProductList = false;
      })
      .addCase(bulkUpdateProducts.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }

        state.savingProductList = false;
      });

    builder
      .addCase(removeProductsFromProductList.pending, (state) => {
        state.fetchingProductListItems = true;
      })
      .addCase(removeProductsFromProductList.fulfilled, (state, action) => {
        state.fetchingProductListItems = false;
        state.count = action.payload.count;
      })
      .addCase(removeProductsFromProductList.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingProductListItems = false;
      });
  },
});

export const getProductList = (state: RootState) => state.productList.value;
export const getProductListCount = (state: RootState) =>
  state.productList.count;
export const getProduct = (state: RootState) => state.productList.product;
export const getfetchingProductListItems = (state: RootState) =>
  state.productList.fetchingProductListItems;
export const getfetchingProductListItemsError = (state: RootState) =>
  state.productList.error;
export const getDeletingProduct = (state: RootState) =>
  state.productList.deletingProduct;

export const {
  setSelectedProducts,
  startAddingProduct,
  finishAddingProduct,
  startRemovingProduct,
  finishRemovingProduct,
} = productListSlice.actions;
export default productListSlice.reducer;
