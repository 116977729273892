import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { useEffect, useState } from 'react';
import DraggableProductItem from './DraggableProductItem';
import DroppableUnityPlayer from './DroppableUnityPlayer';
import ProductsPanel from './ProductsPanel';

const PlanogramView = () => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeProduct, setActiveProduct] = useState<any>(null);
  const [showProductsPanel, setShowProductsPanel] = useState(false);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'SHOW_PRODUCT_LIBRARY') {
        console.log('Message received from Unity');
        setShowProductsPanel(true);
      } else if (event.data.type === 'HIDE_PRODUCT_LIBRARY') {
        console.log('Hide message received from Unity');
        setShowProductsPanel(false);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
    setActiveProduct(event.active.data.current);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;

    if (over && over.id === 'unity-player') {
      if (activeProduct?.id) {
        const unityFrame = document.querySelector('iframe');
        if (unityFrame && unityFrame.contentWindow) {
          unityFrame.contentWindow.SpawnProduct(activeProduct.id);
        } else if ((window as any).unityInstance) {
          (window as any).unityInstance.SendMessage(
            'GameManager',
            'SpawnProduct',
            activeProduct.id
          );
        }
      }
    }

    setActiveId(null);
  };

  const handleCloseProductsPanel = () => {
    setShowProductsPanel(false);
    const unityFrame = document.querySelector('iframe');
    if (unityFrame && unityFrame.contentWindow) {
      unityFrame.contentWindow.HideProductLibrary();
      if ((window as any).unityInstance?.canvas) {
        (window as any).unityInstance.canvas.focus();
      }
    }
  };

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <DroppableUnityPlayer />
      {showProductsPanel && (
        <ProductsPanel onClose={handleCloseProductsPanel} />
      )}
      <DragOverlay>
        {activeId && activeProduct ? (
          <DraggableProductItem
            product={activeProduct}
            index={activeProduct.index}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default PlanogramView;
