import { useMemo, useRef, useState } from 'react';

import { Button, Image, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';

import { POGCheckedUPCProduct } from '../../../../../models/pog-checker';
import { useAppDispatch } from '../../../../../store';
import { addProductsToProductList } from '../../../../../store/features/productList/productListSlice';

const { Text } = Typography;

interface POGCheckedUPCProductsProps {
  upcProducts: POGCheckedUPCProduct[];
  loading: boolean;
}

const POGCheckedUPCProducts: React.FC<POGCheckedUPCProductsProps> = ({
  upcProducts,
  loading = false,
}) => {
  const tableListWrapperEl = useRef<any>();

  const columns: ColumnsType<POGCheckedUPCProduct> = useMemo(
    () => [
      {
        title: 'Model',
        dataIndex: 'data',
        key: 'model',
        fixed: 'left',
        width: 100,
        render: (_, productItem) => {
          return (
            <Image
              src={productItem.item?.image_url}
              alt={`${productItem.item?.name}`}
              preview={false}
            />
          );
        },
      },
      {
        title: 'UPC',
        dataIndex: 'column1',
        key: 'column1',
        render: (upc: string) => {
          return <Text>{upc}</Text>;
        },
        sorter: {
          compare: (u1: POGCheckedUPCProduct, u2: POGCheckedUPCProduct) => {
            if (u1.column1 < u2.column1) {
              return -1;
            } else if (u1.column1 > u2.column1) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 1,
        },
      },
      {
        title: 'Product Name',
        dataIndex: 'column2',
        key: 'column2',
        render: (productName: string) =>
          !!productName ? (
            <Tooltip title={productName} mouseEnterDelay={1}>
              <Text ellipsis style={{ maxWidth: '400px' }}>
                {productName}
              </Text>
            </Tooltip>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No description.
            </Text>
          ),
        sorter: {
          compare: (u1: POGCheckedUPCProduct, u2: POGCheckedUPCProduct) => {
            if (u1.column2 < u2.column2) {
              return -1;
            } else if (u1.column2 > u2.column2) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 2,
        },
      },
      {
        title: 'Brand',
        dataIndex: 'column3',
        key: 'column3',
        render: (brand: string) => {
          return !!brand ? (
            <Text>{brand}</Text>
          ) : (
            <Text type="secondary" style={{ fontStyle: 'italic' }}>
              No description.
            </Text>
          );
        },
        sorter: {
          compare: (u1: POGCheckedUPCProduct, u2: POGCheckedUPCProduct) => {
            if (u1.column3 < u2.column3) {
              return -1;
            } else if (u1.column3 > u2.column3) {
              return 1;
            } else {
              return 0;
            }
          },
          multiple: 3,
        },
      },
    ],
    []
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const hasSelected = selectedRowKeys?.length > 0;
  const isFoundTab = upcProducts?.reduce(
    (acc, { item }) => acc && item?.status !== 'approved',
    true
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<POGCheckedUPCProduct> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleClearSelection = () => {
    setSelectedRowKeys([]);
  };

  const dispatch = useAppDispatch();
  const handleAddSelectionToProductList = (
    selectedProducts: {
      uuid: string;
      in_list: number | null;
    }[]
  ) => {
    setSelectedRowKeys([]);
    const productIds = selectedProducts.map((product) => product.uuid);
    dispatch(addProductsToProductList(productIds));
  };

  const tableFooter = () => (
    <>
      {
        <div className="table-footer-wrapper">
          <div className="table-footer-left">
            <span>
              {hasSelected ? `${selectedRowKeys?.length} Items selected: ` : ''}
            </span>
            <Space size="middle">
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  const selectedProducts = selectedRowKeys?.map(
                    (rowKey) =>
                      upcProducts.find((prod) => prod.column1 === rowKey)?.item!
                  );
                  handleAddSelectionToProductList(selectedProducts);
                }}
                loading={false}
              >
                Add to Product List
              </Button>
            </Space>
          </div>
          <div className="table-footer-right">
            <Button
              type="link"
              onClick={handleClearSelection}
              className="link-button"
            >
              Clear Selection
            </Button>
          </div>
        </div>
      }
    </>
  );

  return (
    <div ref={tableListWrapperEl}>
      <Table
        locale={{ emptyText: <>No Data</> }}
        rowKey={(upcProduct) =>
          `${upcProduct.column1} ${upcProduct.item?.uuid}`
        }
        className="upcs-table-list"
        pagination={{ position: ['bottomRight'] }}
        sortDirections={['ascend', 'descend', 'ascend']}
        columns={columns}
        dataSource={[...upcProducts]}
        rowSelection={rowSelection}
        footer={hasSelected && isFoundTab ? tableFooter : undefined}
      />

      {loading && upcProducts.length > 0 && (
        <div className="loading-more-spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default POGCheckedUPCProducts;
