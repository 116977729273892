import { useDraggable } from '@dnd-kit/core';
import { Image, Tooltip } from 'antd';
import React from 'react';
import { WBMLProduct } from '../../../api/wbml';
import { BASE_API_URL } from '../../../config';
import './DraggableProductItem.less';

interface DraggableProductItemProps {
  product: WBMLProduct;
  index: number;
}

const DraggableProductItem: React.FC<DraggableProductItemProps> = ({
  product,
  index,
}) => {
  const elementRef = React.useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    if (elementRef.current) {
      setDimensions({
        width: elementRef.current.clientWidth,
        height: elementRef.current.clientHeight,
      });
    }
  }, []);

  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: product.id,
      data: {
        index,
        ...product,
      },
    });

  const style: React.CSSProperties | undefined = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        position: 'relative' as const,
        zIndex: isDragging ? 999 : 'auto',
        touchAction: 'none',
        pointerEvents: isDragging ? 'none' : 'auto',
        width: isDragging ? dimensions.width : undefined,
        height: isDragging ? dimensions.height : undefined,
      }
    : undefined;

  const setRefs = React.useCallback(
    (element: HTMLDivElement | null) => {
      setNodeRef(element);
      elementRef.current = element as HTMLDivElement;
    },
    [setNodeRef]
  );

  const {
    upc,
    name,
    size,
    count,
    brand_name,
    manufacturer_name,
    parent_company_name,
    category_name,
    market_name,
    id,
    paths,
  } = product;

  const tooltipContent = (
    <div className="product-tooltip">
      {[
        { label: 'UPC', value: upc },
        { label: 'Name', value: name },
        { label: 'Size', value: size },
        { label: 'Count', value: count },
        { label: 'Brand', value: brand_name || 'N/A' },
        { label: 'Manufacturer', value: manufacturer_name || 'N/A' },
        { label: 'Parent Company', value: parent_company_name || 'N/A' },
        { label: 'Category', value: category_name || 'N/A' },
        { label: 'Market', value: market_name },
      ].map(({ label, value }) =>
        value ? (
          <p key={label}>
            <strong>{label}:</strong> {value}
          </p>
        ) : null
      )}
    </div>
  );

  const renderContent = () => (
    <div
      ref={setRefs}
      className={`draggable-product-item ${isDragging ? 'dragging' : ''}`}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div className="product-image">
        <Image
          src={`${BASE_API_URL()}${paths.image.replace('/api/1', '')}`}
          alt={name}
          preview={false}
          loading="lazy"
          draggable={false}
          key={`${id}-${isDragging}`}
        />
      </div>
      <div className="product-info">
        <p>{upc}</p>
        <p className="product-name">{name}</p>
        <p>{size}</p>
      </div>
    </div>
  );

  return isDragging ? (
    renderContent()
  ) : (
    <Tooltip
      title={tooltipContent}
      placement="right"
      mouseEnterDelay={0.5}
      destroyTooltipOnHide
    >
      {renderContent()}
    </Tooltip>
  );
};

export default DraggableProductItem;
