import { FC, memo, useMemo } from 'react';

import { Space } from 'antd';
import './NoResultsFound.less';

import { useAppSelector } from '../../../store/index';
import { propsAreEqual } from '../../../util';
import Button from '../Button';

interface NoResultsFoundProps {
  searchQuery: string;
  onClear: () => void;
  selectedCategory?: string;
}

const NoResultsFound: FC<NoResultsFoundProps> = (props) => {
  const { searchQuery, onClear, selectedCategory } = props;
  const { categories } = useAppSelector((state) => state.wbml);

  const categoryName = useMemo(() => {
    if (!selectedCategory) return '';
    const category = categories.find((c) => c.id === selectedCategory);
    return category?.name || selectedCategory;
  }, [selectedCategory, categories]);

  const noResultsText =
    searchQuery === '' ? `Category: ${categoryName}` : `"${searchQuery}"`;

  return (
    <div className="no-results-found">
      <Space size="middle" direction="vertical">
        <h3>{`No results for ${noResultsText}`}</h3>
        <Button type="primary" onClick={onClear}>
          Clear Search
        </Button>
      </Space>
    </div>
  );
};

export default memo(NoResultsFound, propsAreEqual);
