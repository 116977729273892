import { Button, Checkbox, Collapse, Space, Spin, Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import React from 'react';
import { useAppSelector } from '../../../store';

const { Text } = Typography;

interface ProductFiltersProps {
  selectedFilters: Record<string, string[]>;
  onFilterChange: (category: string, values: CheckboxValueType[]) => void;
  onClearFilters: () => void;
  filterCategories: FilterCategory[];
}

interface FilterCategory {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
}

const ProductFilters: React.FC<ProductFiltersProps> = ({
  selectedFilters,
  onFilterChange,
  onClearFilters,
  filterCategories,
}) => {
  const { metadataLoading } = useAppSelector((state) => state.wbml);
  const getSelectedFiltersCount = () => {
    return Object.values(selectedFilters).flat().length;
  };

  const getFilterLabel = (category: string, value: string) => {
    const filterCategory = filterCategories.find(
      (cat) => cat.name === category
    );
    const option = filterCategory?.options.find((opt) => opt.value === value);
    return option?.label || value;
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {metadataLoading ? (
        <div className="filters-loading">
          <Spin spinning={metadataLoading} tip="Loading filters..." />
          <Text>Loading filters...</Text>
        </div>
      ) : (
        <>
          {getSelectedFiltersCount() > 0 && (
            <>
              <div className="selected-filters">
                <div className="filter-category-title">SELECTED FILTERS</div>
                {Object.entries(selectedFilters).map(([category, values]) =>
                  values.map((value) => (
                    <div
                      key={`${category}-${value}`}
                      className="selected-filter-item"
                    >
                      {getFilterLabel(category, value)}
                    </div>
                  ))
                )}
              </div>
              <Button onClick={onClearFilters}>Clear All</Button>
            </>
          )}

          <Collapse defaultActiveKey={['0']} ghost>
            {filterCategories.map((category, index) => (
              <Collapse.Panel key={index} header={category.name}>
                <Checkbox.Group
                  options={category.options}
                  value={selectedFilters[category.name] || []}
                  onChange={(values) => onFilterChange(category.name, values)}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                  disabled={metadataLoading}
                />
              </Collapse.Panel>
            ))}
          </Collapse>
        </>
      )}
    </Space>
  );
};

export default ProductFilters;
