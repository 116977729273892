import React from 'react';
import './InitialSearchState.less';

const InitialSearchState: React.FC = () => {
  return (
    <div className="initial-search-state">
      <p>You can search by UPC, product name, or category</p>
    </div>
  );
};

export default InitialSearchState;
