import { useDroppable } from '@dnd-kit/core';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppLayoutContext } from '../AppRoot/AppRoot';
import './DroppableUnityPlayer.less';

const DroppableUnityPlayer = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { sidebarCollapsed } = useContext(AppLayoutContext);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { setNodeRef, isOver } = useDroppable({
    id: 'unity-player',
  });

  useEffect(() => {
    const calculateDimensions = () => {
      const UNITY_TOOLBAR_WIDTH = 35;
      const sidebarWidth = sidebarCollapsed ? 80 : 250;
      const availableWidth =
        window.innerWidth - sidebarWidth - UNITY_TOOLBAR_WIDTH;
      const availableHeight = window.innerHeight - 68;

      setDimensions({
        width: availableWidth,
        height: availableHeight,
      });
    };

    calculateDimensions();
    window.addEventListener('resize', calculateDimensions);

    return () => {
      window.removeEventListener('resize', calculateDimensions);
    };
  }, [sidebarCollapsed]);

  const unityUrl = useMemo(() => {
    const timestamp = Date.now();

    const url =
      process.env.NODE_ENV === 'development'
        ? `/unity/unity.html?v=${timestamp}`
        : `https://portal.dev.readysetvr.com/unity/unity.html?v=${timestamp}`;

    console.log('Unity source:', url);

    return url;
  }, []);

  return (
    <div ref={setNodeRef} className="unity-player-wrapper">
      <iframe
        ref={iframeRef}
        src={unityUrl}
        title="Unity WebGL"
        className="unity-player"
        width={dimensions.width}
        height={dimensions.height}
      />
      {isOver && <div className="drop-overlay">Release to drop</div>}
    </div>
  );
};

export default DroppableUnityPlayer;
