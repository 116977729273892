import React, { memo, useCallback, useMemo, useState } from 'react';

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  ConfigProvider,
  Modal,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Link, withRouter } from 'react-router-dom';

import { ApiError, handleError } from '../../../../api/base';
import { ListProductLibraryModel } from '../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { searchProductsByNameMasterLibrarianList } from '../../../../store/features/masterLibrarianList/masterLibrarianListSlice';
import { deleteProductItem } from '../../../../store/features/productItem/productItemSlice';
import { setSelectedProducts } from '../../../../store/features/productList/productListSlice';
import { propsAreEqual } from '../../../../util';
import { DrawerHashRoute } from '../../../containers/Drawers/types';
import { customizeRenderEmpty } from '../../../containers/Tables/ProductsTable/components/customizeRenderEmpty';
import './MasterLibrarianListTable.less';

const { Title } = Typography;

interface MasterLibrarianListTableProps {
  dataSource: ListProductLibraryModel[];
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  tableHeight?: string;
}

const MasterLibrarianListTable = ({
  dataSource,
  headerTitle,
  columns,
  noDataDescription,
  tableHeight,
}: MasterLibrarianListTableProps) => {
  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );

  const dispatch = useAppDispatch();

  const { selectedProducts } = useAppSelector((state) => state.productList);
  const { fetchingProductLibraryItems } = useAppSelector(
    (state) => state.productLibrary
  );
  const { searchQuery } = useAppSelector((state) => state.masterLibrarianList);

  const [processing, setProcessing] = useState(false);

  const hasSelected = selectedProductRowKeys.length > 0;

  const header = useMemo(
    (): JSX.Element | null =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge
            size="default"
            count={dataSource.length}
            className="total-count-badge"
          />
        </div>
      ) : null,
    [headerTitle, dataSource.length]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const handleOnChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ListProductLibraryModel[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProducts(selectedRows));
  };

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleDeleteSelectedProducts = useCallback(
    async (productsToBeDeleted: any) => {
      try {
        setProcessing(true);
        message.loading({
          content: 'Deleting products...',
          key: 'deleting-products-message',
          duration: 0,
        });

        // Delete products one by one
        for (const product of productsToBeDeleted) {
          const resp = await dispatch(deleteProductItem(product.uuid));
          if (resp.error) {
            throw new Error(resp.payload.error);
          }
        }

        message.destroy('deleting-products-message');

        await dispatch(searchProductsByNameMasterLibrarianList(searchQuery));
        message.success(
          `Successfully deleted ${productsToBeDeleted.length} products`
        );
      } catch (error) {
        handleError(error as ApiError);
      } finally {
        handleClearSelection();
        setProcessing(false);
      }
    },
    [dispatch, handleClearSelection, searchQuery]
  );

  const handleConfirmDeleteProduct = useCallback(
    (e: any, product: any) => {
      e.preventDefault();
      Modal.confirm({
        title: `Are you sure you want to delete?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will no longer be able to add products to the list or update it after it has been deleted.`,
        okType: 'danger',
        okText: 'Delete',
        onOk: () => handleDeleteSelectedProducts(product),
      });
    },
    [handleDeleteSelectedProducts]
  );

  const tableFooter = () => (
    <>
      <div className="table-footer-wrapper">
        <div className="table-footer-left">
          <span>
            {hasSelected
              ? `${selectedProductRowKeys.length} ${
                  selectedProductRowKeys.length === 1 ? 'Item' : 'Items'
                } selected: `
              : ''}
          </span>
          <Space size="middle">
            <Button
              type="primary"
              onClick={(e) => handleConfirmDeleteProduct(e, selectedProducts)}
              style={{ minWidth: '200px' }}
              icon={processing ? <LoadingOutlined /> : null}
            >
              {processing ? 'Deleting...' : 'Delete Selected Product(s)'}
            </Button>

            <Link to={DrawerHashRoute.BulkEditProductsForm}>
              <Button type="primary" loading={false}>
                Bulk Edit
              </Button>
            </Link>
          </Space>
        </div>
        <div className="table-footer-right">
          <Button
            type="link"
            onClick={handleClearSelection}
            className="link-button"
          >
            Clear Selection
          </Button>
        </div>
      </div>
    </>
  );

  const footerContent = hasSelected ? tableFooter : undefined;

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{ y: tableHeight || 240 }}
          rowSelection={rowSelection}
          loading={fetchingProductLibraryItems}
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(
  memo(MasterLibrarianListTable, propsAreEqual)
);
