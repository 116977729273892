import { memo, useCallback, useMemo, useState } from 'react';

import cx from 'classnames';
import 'react-quill/dist/quill.snow.css';

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PictureOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
} from 'antd';

import './ProjectCell.less';

import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import { ApiError, handleError } from '../../../../../api/base';
import {
  CellGroupModel,
  CellModel,
  CellSide,
  ProjectCellModel,
} from '../../../../../models/online-virtual-research';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { setCellsDrawerVisible } from '../../../../../store/features/cells/cellsSlice';
import {
  deleteOvrProjectCellMediaFile,
  getOvrProjectCellSignedUrl,
  ovrProjectCellMediaServiceUploadNotification,
  uploadImageFileToS3Thunk,
} from '../../../../../store/features/media/mediaSlice';
import {
  addProjectCell,
  deleteProjectCell,
  fetchOvrProject,
  removeLastCell,
  selectMediaFilesByCellId,
  setProjectCellId,
  updateProjectCell,
  updateProjectCellsLocally,
} from '../../../../../store/features/ovrProjectDetails/ovrProjectDetailsSlice';
import { propsAreEqual } from '../../../../../util';

const { Option } = Select;
const { Text } = Typography;

interface ProjectCellProps {
  projectCell: ProjectCellModel;
  cellGroup: CellGroupModel;
  projectCellIndex: number;
  odds: string;
}

const ProjectCell = ({
  projectCell,
  cellGroup,
  projectCellIndex,
  odds,
}: ProjectCellProps) => {
  const imageUrl =
    projectCell &&
    projectCell.media_files &&
    projectCell?.media_files?.length > 0
      ? projectCell.media_files[projectCell.media_files.length - 1].media_url
      : undefined;

  const mediaFiles = useAppSelector((state) =>
    selectMediaFilesByCellId(state, projectCell?.uuid!)
  );

  const isNewCell = !projectCell?.uuid;
  const dispatch = useAppDispatch();
  const cellGroups = useAppSelector(
    (state) => state.ovrProjectDetails.ovrProject?.cellGroups
  );

  const { ovrProject, isAddingProjectCell, isUpdatingProjectCell } =
    useAppSelector((state) => state.ovrProjectDetails);

  const {
    isGettingOvrProjectCellSignedUrl,
    isUploadingToS3,
    isOvrProjectCellMediaServiceUploading,
    isDeletingOvrProjectMediaFile,
  } = useAppSelector((state) => state.media);

  const [editorHtml, setEditorHtml] = useState(projectCell?.message || '');
  const [isEditing, setIsEditing] = useState(isNewCell ? true : false);
  const [startLocation, setStartLocation] = useState<CellSide>(
    projectCell?.startLocation!
  );
  const [cellSelection, setCellSelection] = useState<CellModel | undefined>(
    undefined
  );
  const [imageFiles, setImageFiles] = useState<UploadFile<any>[]>([]);
  const [imageVisible, setImageVisible] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);

  const cellOptions = useMemo(() => {
    if (!ovrProject) return [];

    const { cellGroups } = ovrProject!;
    const group0 = cellGroups?.find((group) => group.group_id === 0);

    const uniqueCells = group0?.projectCells?.reduce((acc, pCell) => {
      if (pCell.cell && !acc.some((cell) => cell.uuid === pCell?.cell?.uuid)) {
        acc.push(pCell.cell);
      }
      return acc;
    }, [] as CellModel[]);

    return uniqueCells || [];
  }, [ovrProject]);

  const isProcessingUpload =
    isGettingOvrProjectCellSignedUrl ||
    isUploadingToS3 ||
    isOvrProjectCellMediaServiceUploading ||
    isAddingProjectCell ||
    isUpdatingProjectCell;

  const [form] = Form.useForm();

  const itemActionsClass = cx(
    'cell-item-section',
    { 'align-end': !hasValidationErrors },
    { 'align-center': hasValidationErrors },
    'row'
  );

  const modules = {
    toolbar: [['bold', 'italic']],
  };

  const formats = ['bold', 'italic'];

  const onDeleteSuccess = useCallback(() => {
    setIsDeleting(false);
    message.success('Cell deleted.');
  }, []);

  const onDeleteError = useCallback((err: ApiError) => {
    setIsDeleting(false);
    handleError(err);
  }, []);

  const onUploadImageError = useCallback((error: ApiError) => {
    setIsUploadingImage(false);
    handleError(error);
  }, []);

  const onUploadImageSuccess = useCallback(() => {
    setIsUploadingImage(false);
  }, []);

  const onSaveSuccess = useCallback(() => {
    setIsEditing(false);
    setIsUploadingImage(false);
    setImageFiles([]);
    message.success(`Cell saved.`);
  }, []);

  const onSaveError = useCallback((err: ApiError) => {
    handleError(err);
    setIsEditing(false);
  }, []);

  const uploadImageFileToS3 = useCallback(
    async (signedUrl: string, file: UploadFile<any>) => {
      try {
        await dispatch(uploadImageFileToS3Thunk({ signedUrl, file })).unwrap();
      } catch (error) {
        onUploadImageError(error as ApiError);
      }
    },
    [dispatch, onUploadImageError]
  );

  const processImageFileUpload = useCallback(
    async (file: UploadFile<any>, projectCellId: string) => {
      try {
        setIsUploadingImage(true);

        // get signed url
        const signedUrlResponse = await dispatch(
          getOvrProjectCellSignedUrl({ fileName: file.name, projectCellId })
        ).unwrap();

        // upload image file to s3
        await uploadImageFileToS3(signedUrlResponse.signedUrl, file);

        // notify media service
        await dispatch(
          ovrProjectCellMediaServiceUploadNotification({
            objectKey: signedUrlResponse.objectKey,
            name: file.name,
            size: file.size!,
            uuid: projectCellId,
            media_id: signedUrlResponse.media_id,
            media_version_id: signedUrlResponse.media_version_id,
            obj: { status: 'completed' },
          })
        ).unwrap();
        onUploadImageSuccess();
      } catch (error) {
        onUploadImageError(error as ApiError);
      }
    },
    [dispatch, onUploadImageError, onUploadImageSuccess, uploadImageFileToS3]
  );

  const handleEditCell = async () => {
    setIsEditing(true);
  };

  const handleCancelEditCell = async () => {
    const selectedProjectCell = cellGroup.projectCells.find(
      (_, index) => index === projectCellIndex
    );

    if (!selectedProjectCell?.message) {
      dispatch(removeLastCell({ cellGroupId: cellGroup.group_id }));
    }

    setEditorHtml(projectCell?.message);
    setIsEditing(false);
  };

  const handleSelectCell = (cellName: string) => {
    const selectedCell = cellOptions?.find((cell) => cell?.name === cellName);

    setCellSelection(selectedCell);
  };

  const handleMessageChange = (html: string) => {
    setEditorHtml(html);
    form.setFieldsValue({ message: html });
  };

  const isOnlyCell = useMemo((): boolean => {
    const group = cellGroups?.find(
      (group) => group.group_id === projectCell?.group_id
    );

    return group?.projectCells.length! === 1;
  }, [projectCell?.group_id, cellGroups]);

  const handleRemoveCell = useCallback(async () => {
    if (isOnlyCell) {
      message.error(
        'You must have at least one cell per cell group. Try adding another cell first, then deleting the current one.',
        6
      );
      return;
    }

    if (projectCell && cellGroups) {
      if (isNewCell) {
        dispatch(
          updateProjectCellsLocally({
            groupId: cellGroup.group_id,
            updatedProjectCells: cellGroup.projectCells.filter(
              (cell) => cell !== projectCell
            ),
          })
        );
        return;
      }

      setIsDeleting(true);

      try {
        await dispatch(
          deleteProjectCell({
            projectId: ovrProject?.uuid!,
            projectCellId: projectCell?.uuid!,
            projectCellIndex,
            cellGroupId: cellGroup.group_id,
          })
        ).unwrap();
        onDeleteSuccess();
      } catch (error) {
        onDeleteError(error as ApiError);
      }
    }
  }, [
    isOnlyCell,
    projectCell,
    cellGroups,
    isNewCell,
    dispatch,
    cellGroup.group_id,
    cellGroup.projectCells,
    ovrProject?.uuid,
    projectCellIndex,
    onDeleteSuccess,
    onDeleteError,
  ]);

  const handleConfirmRemoveCell = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, cell: CellModel) => {
      e.preventDefault();
      const title = isNewCell
        ? 'Are you sure you want to delete the cell?'
        : `Are you sure you want to delete the cell "${cell.name}?`;
      Modal.confirm({
        title: title,
        icon: <ExclamationCircleOutlined />,
        okType: 'danger',
        okText: 'Delete Cell',
        onOk: () => handleRemoveCell(),
      });
    },
    [handleRemoveCell, isNewCell]
  );

  const saveCell = useCallback(async () => {
    const sanitizedMessage = DOMPurify.sanitize(editorHtml);

    const messageWithCorrectTags = sanitizedMessage
      .replace(/<strong>(.*?)<\/strong>/g, '<b>$1</b>')
      .replace(/<em>(.*?)<\/em>/g, '<i>$1</i>')
      .replace(/<p><br><\/p>/g, '<br><br>') // Convert double line breaks to <br><br>
      .replace(/<p>(.*?)<\/p>/g, '$1<br>'); // Convert single line breaks to <br>

    if (isNewCell) {
      try {
        dispatch(setProjectCellId(projectCell?.uuid!));
        // create the new project cell
        const addProjectCellRes = await dispatch(
          addProjectCell({
            projectId: ovrProject?.uuid!,
            projectCellIndex,
            projectCell: {
              group_id: projectCell?.group_id!,
              message: messageWithCorrectTags,
              cell: cellSelection?.uuid!,
              startLocation,
            },
          })
        ).unwrap();

        // get the new project cell id and upload message prompt image if there is one
        if (addProjectCellRes) {
          const projectCellId = addProjectCellRes.data.uuid;
          if (imageFiles.length > 0 && projectCellId) {
            await processImageFileUpload(imageFiles[0], projectCellId);
          }

          await dispatch(
            fetchOvrProject({
              projectId: ovrProject?.uuid!,
            })
          ).unwrap();

          onSaveSuccess();
        }
      } catch (error) {
        onSaveError(error as ApiError);
      }
    } else {
      try {
        dispatch(setProjectCellId(projectCell?.uuid!));
        // todo: might need to include cell img url here
        const upadateProjectCellRes = await dispatch(
          updateProjectCell({
            projectId: ovrProject?.uuid!,
            projectCellId: projectCell?.uuid!,
            projectCellIndex,
            updatedProjectCell: {
              group_id: projectCell?.group_id!,
              message: messageWithCorrectTags,
              ovr_cell_id: cellSelection?.uuid!,
              startLocation,
            },
          })
        ).unwrap();

        // get the new project cell id and upload message prompt image if there is one
        if (upadateProjectCellRes) {
          const projectCellId = upadateProjectCellRes.data.uuid;
          if (imageFiles.length > 0 && projectCellId) {
            await processImageFileUpload(imageFiles[0], projectCellId);
          }

          await dispatch(
            fetchOvrProject({
              projectId: ovrProject?.uuid!,
            })
          ).unwrap();

          onSaveSuccess();
        }
      } catch (error) {
        onSaveError(error as ApiError);
      }
    }
  }, [
    cellSelection?.uuid,
    dispatch,
    editorHtml,
    imageFiles,
    isNewCell,
    onSaveError,
    onSaveSuccess,
    ovrProject?.uuid,
    processImageFileUpload,
    projectCell,
    projectCellIndex,
    startLocation,
  ]);

  const handleSaveCell = useCallback(() => {
    setHasValidationErrors(false);

    form
      .validateFields([['cell', 'name'], 'message', 'startLocation'])
      .then(saveCell)
      .catch(() => setHasValidationErrors(true));
  }, [form, saveCell]);

  const handleCellSideChange = (cellSide: CellSide) => {
    setStartLocation(cellSide);
  };

  const handleImageUpload = (jpgFile: UploadFile<any>) => {
    const isDuplicate = imageFiles.some((f) => f.name === jpgFile.name);
    if (isDuplicate) {
      message.error('File already exists in the list.');
      return false;
    } else {
      setImageFiles((prevList) => [...prevList, jpgFile]);
    }
    return false;
  };

  const handleImageRemove = (file: UploadFile<any>) => {
    setImageFiles(imageFiles.filter((f) => f?.uid !== file.uid));
  };

  const handlePreviewImage = () => {
    if (!projectCell?.media_files) return;
    setImageVisible(true);
  };

  const handleDeletePreviewImage = async () => {
    try {
      const deleteImageRes = await dispatch(
        deleteOvrProjectCellMediaFile({
          ovrProjectCellId: projectCell?.uuid!,
          mediaVersionId: projectCell?.media_files![0].media_version_id!,
          mediaId: projectCell?.media_files![0].id!,
        })
      );

      if (deleteImageRes.meta.requestStatus === 'fulfilled') {
        message.success('Image deleted.');
        setImageFiles([]);
        dispatch(fetchOvrProject({ projectId: ovrProject?.uuid! }));
      }
    } catch (error) {
      handleError(error as ApiError);
    }
  };

  console.log('mediaFiles :>> ', mediaFiles);

  return (
    <Card className="project-cell">
      <Form
        initialValues={projectCell}
        form={form}
        requiredMark={false}
        layout="vertical"
      >
        <>
          {' '}
          {!isEditing && (
            <Row align={'top'} gutter={[32, 16]}>
              <Col span={8} className="cell-item-section">
                <Space direction="vertical" size={'middle'}>
                  <div>
                    <div className="cell-item-col-title">Cell</div>
                    <Text>{projectCell?.cell?.name}</Text>
                  </div>
                  <div>
                    <div className="cell-item-col-title">Cell Side</div>
                    <Text>{projectCell?.startLocation}</Text>
                  </div>
                </Space>
              </Col>
              <Col span={3} className="cell-item-section">
                <div className="cell-item-col-title">Odds</div>
                <Text>{odds}</Text>
              </Col>

              <Col span={10} className="cell-item-section">
                <div className="cell-item-col-title ">Message</div>
                <div className="message-container">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: projectCell?.message,
                    }}
                  />
                </div>
                <div className="preview-btn-container">
                  {mediaFiles.length > 0 && (
                    <div style={{ width: '100%' }}>
                      <Button
                        icon={<EyeOutlined />}
                        block
                        style={{ width: '100%' }}
                        onClick={handlePreviewImage}
                      >
                        Preview Message Image
                      </Button>
                      {imageVisible && (
                        <Modal
                          visible={imageVisible}
                          footer={null}
                          onCancel={() => setImageVisible(false)}
                          width="auto"
                          bodyStyle={{
                            padding: '0',
                            maxWidth: '90vw',
                            maxHeight: '90vh',
                          }}
                          centered
                        >
                          <img
                            src={imageUrl}
                            alt="Message Preview"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                              borderRadius: '8px',
                            }}
                          />
                        </Modal>
                      )}

                      {/* <Image
                        width={200}
                        style={{ display: 'none' }}
                        src={imageUrl}
                        preview={{
                          visible: imageVisible,
                          src: imageUrl,
                          onVisibleChange: (value) => {
                            setImageVisible(value);
                          },
                        }}
                      /> */}
                    </div>
                  )}
                </div>
              </Col>
              <Col span={3} className={itemActionsClass}>
                <Space direction="vertical" style={{ marginTop: '1.8rem' }}>
                  <Tooltip title="Edit cell">
                    <Button
                      icon={<EditOutlined />}
                      style={{ width: '100%' }}
                      onClick={handleEditCell}
                      disabled={isProcessingUpload}
                    >
                      Edit
                    </Button>
                  </Tooltip>

                  <Tooltip title="Delete cell">
                    <Button
                      disabled={isProcessingUpload}
                      loading={isDeleting}
                      type="primary"
                      style={{ width: '100%' }}
                      icon={<DeleteOutlined />}
                      onClick={(event) =>
                        handleConfirmRemoveCell(event, projectCell?.cell!)
                      }
                      danger
                    >
                      Delete
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          )}
          {isEditing ? (
            <Form.Item
              label="Cell"
              name={['cell', 'name']}
              rules={[{ required: true, message: 'Cell is required.' }]}
            >
              <Select
                key={window.innerWidth}
                dropdownMatchSelectWidth={false}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Cells in Project"
                  >
                    <Button
                      disabled={isProcessingUpload}
                      type="primary"
                      onClick={() => dispatch(setCellsDrawerVisible(true))}
                    >
                      Add Cells
                    </Button>
                  </Empty>
                }
                onChange={(e) => handleSelectCell(e as string)}
                placeholder="Select a cell"
                disabled={false}
              >
                {cellOptions?.map((cell) => (
                  <Option key={cell?.uuid} value={cell?.name}>
                    {cell?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          {isEditing ? (
            <Form.Item
              label="Cell Side"
              name="startLocation"
              rules={[{ required: true, message: 'Cell side is required.' }]}
            >
              <Select onChange={handleCellSideChange} placeholder="Select side">
                <Option key="left" value="Left">
                  Left
                </Option>
                <Option key="center" value="Center">
                  Center
                </Option>
                <Option key="right" value="Right">
                  Right
                </Option>
              </Select>
            </Form.Item>
          ) : null}
          {isEditing ? (
            <>
              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: 'Message is required.' }]}
              >
                <></>
                <ReactQuill
                  value={editorHtml}
                  onChange={handleMessageChange}
                  modules={modules}
                  formats={formats}
                />
              </Form.Item>

              <Form.Item
                name="message_prompt_image"
                rules={[
                  {
                    required: true,
                    message: 'Image is required.',
                  },
                ]}
              >
                {imageUrl && (
                  <Button
                    icon={<PictureOutlined />}
                    onClick={handleDeletePreviewImage}
                    loading={isDeletingOvrProjectMediaFile}
                    disabled={isDeletingOvrProjectMediaFile}
                  >
                    Delete Image
                  </Button>
                )}
                {mediaFiles.length === 0 && (
                  <Tooltip title={`Add an image to the message prompt`}>
                    <Upload
                      beforeUpload={handleImageUpload}
                      onRemove={handleImageRemove}
                      fileList={imageFiles}
                      accept="image/png,image/jpeg"
                      multiple={false}
                      listType="picture"
                    >
                      <Button
                        style={{ marginTop: '.5rem' }}
                        icon={<PictureOutlined />}
                        type="ghost"
                        loading={isUploadingImage}
                        disabled={isProcessingUpload}
                      >
                        Add Image
                      </Button>
                    </Upload>
                  </Tooltip>
                )}
              </Form.Item>
            </>
          ) : null}
          {isEditing ? (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {isEditing ? (
                <>
                  <Tooltip title="Save cell">
                    <Button
                      style={{ marginRight: '1rem' }}
                      type="primary"
                      loading={isProcessingUpload}
                      onClick={handleSaveCell}
                      icon={<SaveOutlined />}
                    >
                      Save
                    </Button>
                  </Tooltip>

                  <Tooltip title="Cancel edit">
                    <Button
                      style={{ marginRight: '1rem' }}
                      type="default"
                      onClick={handleCancelEditCell}
                      disabled={isProcessingUpload}
                    >
                      Cancel
                    </Button>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Edit cell">
                  <Button
                    disabled={isProcessingUpload}
                    icon={<EditOutlined />}
                    style={{ marginRight: '1rem' }}
                    onClick={handleEditCell}
                  >
                    Edit
                  </Button>
                </Tooltip>
              )}
              {
                <Tooltip title="Delete cell">
                  <Button
                    type="primary"
                    style={{ marginRight: '1rem' }}
                    icon={<DeleteOutlined />}
                    onClick={(event) =>
                      handleConfirmRemoveCell(event, projectCell?.cell!)
                    }
                    loading={isDeleting}
                    disabled={isProcessingUpload}
                    danger
                  >
                    Delete
                  </Button>
                </Tooltip>
              }
            </div>
          ) : null}
        </>
      </Form>
    </Card>
  );
};

export default memo(ProjectCell, propsAreEqual);
