import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import AuthService from '../../../api/auth';
import { ApiError, PaginationModel, handleError } from '../../../api/base';
import { PermissionsResponse } from '../../../api/permission';
import UserService from '../../../api/user';
import { UserResponse } from '../../../api/user/reqres';
import { ListUsersParams } from '../../../hooks/user';
import { UserModel } from '../../../models/user';
import { convertParamsToQuery } from '../../../util';
import { RootState } from '../../index';

export interface UserState {
  value: UserModel[];
  user: UserModel | null;
  currentUser: UserModel | null;
  allUsers: UserModel[];
  fetchingUser: boolean;
  fetchingUsers: boolean;
  searchingUsers: boolean;
  savingUser: boolean;
  searchQuery: string;
  error: {
    code: number | null;
    message: string | null;
  };
  pagination: PaginationModel | null;
  totalCount: number;
}

interface CreateUserProps {
  user: UserModel;
  userRoles: string[];
}

interface UpdateUserProps {
  userId: string;
  user: UserModel;
  userRoles: string[];
}

interface SaveUserPermissionsProps {
  resp: UserResponse;
  userPermissions: string[];
}

interface DisableMfaProps {
  apiUser: string;
  apiPass: string;
}

interface UpdateUserClientProps {
  userId: string;
  clientId: string;
}

interface ChangePasswordProps {
  apiUser: string;
  apiPass: string;
  newPassword: string;
}

const initialState: UserState = {
  value: [],
  currentUser: null,
  user: null,
  allUsers: [],
  fetchingUser: false,
  fetchingUsers: false,
  searchQuery: '',
  searchingUsers: false,
  savingUser: false,
  error: { code: null, message: null },
  totalCount: 0,
  pagination: null,
};

const usersParams = {
  params: {
    _limit: 20,
    _order_by: 'updated_at:desc',
    _columns: 'roles.display_name,roles.name,roles.id|asArray',
    _offset: 0,
  },
};

export const saveUserPermissions = createAsyncThunk(
  'saveUserPermissions',
  async (
    { resp, userPermissions }: SaveUserPermissionsProps,
    { rejectWithValue }
  ) => {
    try {
      if (!userPermissions.length) return resp.data;
      const response: PermissionsResponse =
        await UserService().updateUserPermissions(
          resp.data.uuid,
          userPermissions
        );
      const updatedUserPermissions = response.data;
      const savedUser = {
        ...resp.data,
        permissions: updatedUserPermissions,
      };

      return savedUser;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk(
  'users/getUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      const params = {
        _columns: 'roles.display_name,roles.name,roles.id|asArray',
      };

      const query = convertParamsToQuery(params);
      const resp = await UserService().getUser(userId, query);

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  'users/getCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await UserService().getCurrentUser();

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (
    { user, userRoles }: CreateUserProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const resp = await UserService().createUser({
        ...user,
        roles: userRoles,
      });

      await dispatch(fetchUsers(usersParams));

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userId: string, { dispatch, rejectWithValue }) => {
    try {
      const res = await UserService().deleteUser(userId);

      await dispatch(fetchUsers(usersParams));

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (
    { userId, user, userRoles }: UpdateUserProps,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      users: { searchQuery },
    } = getState() as RootState;

    try {
      const resp = await UserService().updateUser(userId, {
        name: user.name,
        email: user.email,
        password: user.password,
        client_id: user.client_id,
        roles: userRoles,
      });

      dispatch(getCurrentUser());
      dispatch(searchUsersByName(searchQuery));

      return resp.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (
    {
      params,
      searching = false,
      fetchingUsersMore = false,
    }: {
      params: ListUsersParams;
      searching?: boolean;
      fetchingUsersMore?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const queryString = convertParamsToQuery(params);
      const resp = await UserService().listUsers(queryString);
      return {
        data: resp.data,
        pagination: resp.pagination,
        searching,
        fetchingUsersMore,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const searchUsersByName = createAsyncThunk(
  'users/searchUsersByName',
  async (query: string, { rejectWithValue }) => {
    try {
      let params: ListUsersParams = {
        ...usersParams.params,
        _limit: 40,
      };

      if (query && query.length) {
        params = {
          ...params,
          name: `*${query.toLowerCase()}*`,
        };
      }

      const queryString = convertParamsToQuery(params);

      const resp = await UserService().listUsers(queryString);

      return {
        data: resp.data,
        pagination: resp.pagination,
        searching: false,
        fetchingUsersMore: false,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const loadMoreUsers = createAsyncThunk(
  'users/loadMoreUsers',
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        users: { pagination },
      } = getState() as RootState;

      const limit = pagination?.limit!;
      const offset = pagination?.offset!;
      const nextOffset = limit + offset;

      const nextParams = {
        _order_by: 'updated_at:desc',
        _columns:
          'roles.display_name,roles.name,roles.id,permissions.key|asArray',
        _limit: limit,
        _offset: nextOffset,
      };

      const query = convertParamsToQuery(nextParams);
      const resp = await UserService().listUsers(query);

      return {
        data: resp.data,
        pagination: resp.pagination,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const disableMfa = createAsyncThunk(
  'users/disableMfa',
  async ({ apiUser, apiPass }: DisableMfaProps, { rejectWithValue }) => {
    try {
      const response = await UserService().disableMfa(apiUser, apiPass);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserClient = createAsyncThunk(
  'users/updateUserClient',
  async (
    { userId, clientId }: UpdateUserClientProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const query = convertParamsToQuery({
        _columns: 'permissions.key|asArray,roles.*',
      });

      const resp = await UserService().updateUser(
        userId,
        { client_id: clientId },
        query
      );

      AuthService().setMe(resp.data);
      dispatch(getCurrentUser());

      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  'users/changePassword',
  async (
    { apiUser, apiPass, newPassword }: ChangePasswordProps,
    { rejectWithValue }
  ) => {
    try {
      const response = await UserService().changePassword(
        apiUser,
        apiPass,
        newPassword
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.fetchingUser = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.fetchingUser = false;
        state.currentUser = action.payload;
        state.error = { code: null, message: null };
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingUser = false;
      });

    builder
      .addCase(getUser.pending, (state) => {
        state.fetchingUser = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.fetchingUser = false;
        state.user = action.payload;
        state.error = { code: null, message: null };
      })
      .addCase(getUser.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingUser = false;
      });

    builder
      .addCase(fetchUsers.pending, (state) => {
        state.fetchingUsers = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action: any) => {
        state.totalCount = action.payload.pagination.count;
        state.fetchingUsers = false;
        state.value = action.payload.data;
        state.allUsers = action.payload.data;
        state.pagination = action.payload.pagination;
        state.error = { code: null, message: null };
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingUser = false;
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.savingUser = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.savingUser = false;
        state.error = { code: null, message: null };
      })
      .addCase(createUser.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingUser = false;
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.savingUser = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.savingUser = false;
        state.error = { code: null, message: null };
      })
      .addCase(updateUser.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingUser = false;
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.fetchingUsers = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.fetchingUsers = false;
        state.error = { code: null, message: null };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.fetchingUsers = false;
      });

    builder
      .addCase(saveUserPermissions.pending, (state) => {
        state.savingUser = true;
      })
      .addCase(saveUserPermissions.fulfilled, (state) => {
        state.savingUser = false;
        state.error = { code: null, message: null };
      })
      .addCase(saveUserPermissions.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingUser = false;
      });

    builder
      .addCase(searchUsersByName.pending, (state) => {
        state.searchingUsers = true;
      })
      .addCase(searchUsersByName.fulfilled, (state, action) => {
        state.searchingUsers = false;
        state.error = { code: null, message: null };
        if (action.payload) {
          state.value = action.payload.data;
          state.pagination = action.payload.pagination;
          state.allUsers = action.payload.data;
          state.totalCount = action.payload.pagination.count;
        }
      })
      .addCase(searchUsersByName.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.searchingUsers = false;
      });

    builder.addCase(loadMoreUsers.pending, (state) => {
      state.fetchingUsers = true;
    });
    builder.addCase(loadMoreUsers.fulfilled, (state, action: any) => {
      state.fetchingUsers = false;
      state.error = { code: null, message: null };

      state.pagination = action.payload.pagination;
      state.value = [...state.value, ...action.payload.data];
    });
    builder.addCase(loadMoreUsers.rejected, (state, action) => {
      if (action.error.message) {
        if ((action.payload as unknown as ApiError).error) {
          state.error = (action.payload as unknown as ApiError).error;
          handleError(action.payload as unknown as ApiError);
        }
      }
      state.fetchingUsers = false;
    });

    builder
      .addCase(disableMfa.pending, (state) => {
        state.savingUser = true;
      })
      .addCase(disableMfa.fulfilled, (state) => {
        state.savingUser = false;
        state.error = { code: null, message: null };
      })
      .addCase(disableMfa.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingUser = false;
      });

    builder
      .addCase(changePassword.pending, (state) => {
        state.savingUser = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.savingUser = false;
        state.error = { code: null, message: null };
      })
      .addCase(changePassword.rejected, (state, action) => {
        if (action.error.message) {
          if ((action.payload as unknown as ApiError).error) {
            state.error = (action.payload as unknown as ApiError).error;
            handleError(action.payload as unknown as ApiError);
          }
        }
        state.savingUser = false;
      });
  },
});

export const { setUserSearchQuery } = usersSlice.actions;

export const getUsers = (state: RootState) => state.users.value;
export const getFetchingUsers = (state: RootState) => state.users.fetchingUsers;
export const getFetchingUsersError = (state: RootState) => state.users.error;
export const getTotalCount = (state: RootState) => state.users.totalCount;
export const getPagination = (state: RootState) => state.users.pagination;
export const getSearchingUsersByName = (state: RootState) =>
  state.users.searchingUsers;
export const getSavingUser = (state: RootState) => state.users.savingUser;
export const getAllUsers = (state: RootState) => state.users.allUsers;

export default usersSlice.reducer;
