import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from '../api/auth';
import ClientService from '../api/client';
import { RoutePath } from '../components/views/AppRoot/types';

export const useMfaCheck = () => {
  const history = useHistory();

  useEffect(() => {
    const checkMfaStatus = async () => {
      try {
        const me = localStorage.getItem('me');
        if (!me) return;

        const userData = JSON.parse(me);

        let currentClientMfaStatus = 'disabled';
        try {
          // fetch current client MFA status from server
          const clientResp = await ClientService().getClientMFAStatus(
            userData.client_id
          );
          currentClientMfaStatus = clientResp.data.mfa_status;
        } catch (error) {
          // Temporary fix for 403 error
          console.warn('Unable to fetch MFA status, defaulting to disabled');
        }

        // Update localStorage with current status
        localStorage.setItem('client_mfa_status', currentClientMfaStatus);

        // Check if MFA should be enforced
        if (
          currentClientMfaStatus === 'required' &&
          userData.mfa_status === 'disabled'
        ) {
          AuthService().logout();
          history.replace(RoutePath.LogIn);
        }
      } catch (error) {
        console.error('Error checking MFA status:', error);
        AuthService().logout();
        history.replace(RoutePath.LogIn);
      }
    };

    checkMfaStatus();
  }, [history]);
};
