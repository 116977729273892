import { BASE_API_URL } from '../../config';
import { get } from '../base';
import { WBMLSearchParams, WBMLSearchResponse } from './reqres';

export interface WBMLCategory {
  id: string;
  name: string;
  parent_category_id: string;
  parent_names: string;
  parent_name: string | null;
}

export type MetadataType =
  | 'categories'
  | 'brands'
  | 'markets'
  | 'parentCompanies'
  | 'manufacturers'
  | 'formats'
  | 'tags';

export interface WBMLMetadata {
  id: string;
  name: string;
  manufacturer_id?: string; // For brands
  parent_company_id?: string; // For manufacturers
}

export interface WBMLMetadataResponse {
  data: {
    [key in MetadataType]?: WBMLMetadata[];
  };
}

export default function WBMLService() {
  return Object.freeze({
    search(params: WBMLSearchParams): Promise<WBMLSearchResponse> {
      const queryString = new URLSearchParams(
        params as Record<string, string>
      ).toString();
      return get(
        `${BASE_API_URL()}/wbml/search?${queryString}`
      ) as Promise<WBMLSearchResponse>;
    },
    getCategories() {
      return get(`${BASE_API_URL()}/wbml/categories`) as Promise<{
        data: { categories: WBMLCategory[] };
      }>;
    },
    getMetadata() {
      return get(
        `${BASE_API_URL()}/wbml/meta`
      ) as Promise<WBMLMetadataResponse>;
    },
    getMetadataByType(type: MetadataType) {
      return get(`${BASE_API_URL()}/wbml/meta/${type}`) as Promise<{
        data: WBMLMetadata[];
      }>;
    },
  });
}
