import { Image, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import imagePlaceholder from '../../../assets/images/product-image-placeholder.png';
import { ListProductLibraryModel } from '../../../models/product-library';
import { formatDate } from '../../../util';

const { Text } = Typography;

export const uploadModelColumns: ColumnsType<ListProductLibraryModel> = [
  {
    title: 'Model',
    dataIndex: 'data',
    key: 'model',
    fixed: 'left',
    width: 100,
    render: (_, productItem) => {
      return (
        <Link
          to={{
            pathname: `/product-library/${productItem.uuid}`,
            state: {
              productItem,
            },
          }}
        >
          <Image
            style={{
              width: '100%',
              maxHeight: '130px',
              objectFit: 'cover',
            }}
            src={productItem.image_url || imagePlaceholder}
            alt={`${productItem.name}`}
            preview={false}
          />
        </Link>
      );
    },
  },
  {
    title: 'Media Version Created Date',
    dataIndex: 'data',
    key: 'media_version_created_date',
    render: (_, productItem) => (
      <div>
        {productItem?.media_files?.[0]?.created_at
          ? formatDate(productItem?.media_files?.[0]?.created_at)
          : 'N/A'}
      </div>
    ),
    sorter: (
      a: { created_at: string | number | Date },
      b: { created_at: string | number | Date }
    ) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
  },
  {
    title: 'UPC',
    dataIndex: 'upc',
    key: 'upc',
    width: 150,
    render: (upc: string) =>
      !!upc ? (
        <Tooltip title={upc} mouseEnterDelay={1}>
          <Text ellipsis style={{ maxWidth: '400px' }}>
            {upc}
          </Text>
        </Tooltip>
      ) : (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          N/A
        </Text>
      ),
    defaultSortOrder: 'descend',
    sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
      Number(`${a.upc}`.replace(/\D/g, '')) -
      Number(`${b.upc}`.replace(/\D/g, '')),
  },
  {
    title: 'Product Name',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    render: (productName: string) =>
      !!productName ? (
        <Tooltip title={productName} mouseEnterDelay={1}>
          <Text style={{ maxWidth: '150px' }}>{productName}</Text>
        </Tooltip>
      ) : (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          No product name.
        </Text>
      ),
    defaultSortOrder: 'descend',
    sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
      a.name.localeCompare(b.name),
  },
  {
    title: 'Brand',
    dataIndex: 'brand_name',
    key: 'brand',
    width: 100,
    render: (brand: string) =>
      !!brand ? (
        <Tooltip title={brand} mouseEnterDelay={1}>
          <Text style={{ maxWidth: '150px' }}>{brand}</Text>
        </Tooltip>
      ) : (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          No brand.
        </Text>
      ),
    defaultSortOrder: 'descend',
    sorter: (a, b) => a?.brand_name?.localeCompare(b?.brand_name || '') || 0,
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    width: 150,
    render: (manufacturer: string) =>
      !!manufacturer ? (
        <Tooltip title={manufacturer} mouseEnterDelay={1}>
          <Text ellipsis style={{ maxWidth: '400px' }}>
            {manufacturer}
          </Text>
        </Tooltip>
      ) : (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          No manufacturer.
        </Text>
      ),
    defaultSortOrder: 'descend',
    sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
      a?.manufacturer?.localeCompare(b?.manufacturer),
  },
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
    width: 100,
    render: (size: string) =>
      !!size ? (
        <Tooltip title={size} mouseEnterDelay={1}>
          <Text style={{ maxWidth: '150px' }}>{size}</Text>
        </Tooltip>
      ) : (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          No size.
        </Text>
      ),
    defaultSortOrder: 'descend',
    sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) =>
      Number((a.size as string).replace(/\D/g, '')) -
      Number((b.size as string).replace(/\D/g, '')),
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    width: 100,
    render: (count: string) =>
      !!count ? (
        <Tooltip title={count} mouseEnterDelay={1}>
          <Text style={{ maxWidth: '150px' }}>{count}</Text>
        </Tooltip>
      ) : (
        <Text type="secondary" style={{ fontStyle: 'italic' }}>
          No count.
        </Text>
      ),
    defaultSortOrder: 'descend',
    sorter: (a: ListProductLibraryModel, b: ListProductLibraryModel) => {
      if (a?.count && b?.count) {
        return (
          Number((a.count as string).replace(/\D/g, '')) -
          Number((b.count as string).replace(/\D/g, ''))
        );
      } else if (a?.count) {
        return 1;
      } else if (b?.count) {
        return -1;
      } else {
        return 0;
      }
    },
  },
];
