export enum RoutePath {
  LogIn = '/log-in',
  Planogram = '/planogram',
  AccountSettings = '/account-settings/:id',
  ForgotPassword = '/forgot-password',
  Dashboard = '/dashboard',
  StoreBuilder = '/store-builder',
  StoreBuilderDetails = '/store-builder/:id',
  OVR = '/online-virtual-research',
  OVRDetails = '/online-virtual-research/:id',
  CustomFields = '/custom-fields',
  Manual = '/manual',
  Users = '/users',
  User = '/users/:id',
  Client = '/clients/:id',
  Clients = '/clients',
  SystemConfigurations = '/system-configuration',
  OvrFileManagement = '/ovr-file-management',
  Products = '/products',
  ProductDetails = '/product-library/:id',
  ProductDetailsImage = '/product-library/:id/image',
  ProductLibrary = '/product-library',
  ProductList = '/product-list',
  MasterLibrarianList = '/master-librarian-list',
  UploadModels = '/upload-models',
  POGChecker = '/pog-checker',
  MetaData = '/meta-data',
  ReplacementData = '/replacement-data',
  Status = '/status',
  UnitedStates = '/united-states',
  Canada = '/canada',
  Global = '/global',
  Roles = '/roles',
}
