import React, { memo, useCallback, useMemo, useState } from 'react';

import { Badge, Button, ConfigProvider, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useLocation, withRouter } from 'react-router-dom';

import { ListProductLibraryParams } from '../../../../../../../hooks/product-library';
import { ListProductLibraryModel } from '../../../../../../../models/product-library';
import { useAppDispatch, useAppSelector } from '../../../../../../../store';
import { filterProducts } from '../../../../../../../store/features/productLibrary/productLibrarySlice';
import {
  addProductsToProductList,
  removeProductsFromProductList,
  setSelectedProducts,
} from '../../../../../../../store/features/productList/productListSlice';
import { propsAreEqual } from '../../../../../../../util';
import { customizeRenderEmpty } from '../../../../../../containers/Tables/ProductsTable/components/customizeRenderEmpty';
import { FiltersType } from '../../../ProductsFilters/ProductsFilters';
import './ProductLibraryTable.less';

const { Title } = Typography;

interface ProductsTableProps {
  dataSource: ListProductLibraryModel[];
  headerTitle: string;
  columns: ColumnsType<ListProductLibraryModel>;
  noDataDescription?: string;
  tableHeight?: string;
}

interface SelectedProduct {
  uuid: string;
  in_list: number | null;
}

const ProductLibraryTable = ({
  dataSource,
  headerTitle,
  columns,
  noDataDescription,
  tableHeight,
}: ProductsTableProps) => {
  const [selectedProductRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    []
  );

  const hasSelected = selectedProductRowKeys.length > 0;
  const dispatch = useAppDispatch();

  const { selectedProducts } = useAppSelector((state) => state.productList);
  const {
    selectedCategory,
    selectedFilters,
    searchQuery,
    pageSize,
    currentPage,
    fetchingProductLibraryItems,
  } = useAppSelector((state) => state.productLibrary);

  const location = useLocation();
  const isProductListRoute = location.pathname === '/product-list';
  const isProductLibraryRoute = location.pathname === '/product-library';

  const showRemoveButton =
    isProductListRoute ||
    (isProductLibraryRoute &&
      selectedProducts.every((product) => product.in_list === 1));

  const header = useMemo(
    (): JSX.Element | null =>
      headerTitle ? (
        <div className="products-table-header">
          <Title level={3}>{headerTitle}</Title>
          <Badge
            size="default"
            count={dataSource.length}
            className="total-count-badge"
          />
        </div>
      ) : null,
    [headerTitle, dataSource.length]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedRowKeys([]);
  }, []);

  const [isAddingProducts, setIsAddingProducts] = useState(false);
  const [isRemovingProducts, setIsRemovingProducts] = useState(false);

  const handleRemoveFromProductList = async (
    selectedProducts: ListProductLibraryModel[]
  ) => {
    try {
      setIsRemovingProducts(true);
      // Remove products from list
      const productIds = selectedProducts.map((product) => product.uuid);
      await dispatch(removeProductsFromProductList(productIds));

      // Build params object with all current filters
      let params: ListProductLibraryParams = {
        _limit: pageSize,
        _offset: (currentPage! - 1) * pageSize!,
        _order_by: 'updated_at:desc',
        status: 'approved',
      };

      // Add category filter if selected
      if (selectedCategory && selectedCategory !== 'All') {
        params.category = selectedCategory.key;
      }

      // Add other filters if present
      if (selectedFilters) {
        Object.keys(selectedFilters).forEach((filterGroup) => {
          const filterKey = filterGroup as keyof FiltersType;
          if (selectedFilters[filterKey]?.length) {
            params = {
              ...params,
              [filterGroup]: selectedFilters[filterKey],
            };
          }
        });
      }

      // Dispatch filter action with both params and search query
      await dispatch(filterProducts({ params, query: searchQuery }));

      // Clear selection
      setSelectedRowKeys([]);
    } catch (error) {
      console.error('Error removing products from list:', error);
    } finally {
      setIsRemovingProducts(false);
    }
  };

  const tableFooter = () => (
    <>
      <div className="table-footer-wrapper">
        <div className="table-footer-left">
          <span>
            {hasSelected
              ? `${selectedProductRowKeys.length} ${
                  selectedProductRowKeys.length === 1 ? 'Item' : 'Items'
                } selected: `
              : ''}
          </span>
          <Space size="middle">
            {showRemoveButton && (
              <Button
                type="primary"
                onClick={() => handleRemoveFromProductList(selectedProducts)}
                loading={isRemovingProducts}
                style={{ minWidth: '140px' }}
              >
                {isRemovingProducts ? '' : 'Remove from List'}
              </Button>
            )}

            {selectedProducts.every((product) => product.in_list === 0) && (
              <Button
                type="primary"
                onClick={() => handleAddToProductList(selectedProducts)}
                loading={isAddingProducts}
                style={{ minWidth: '140px' }}
              >
                {isAddingProducts ? '' : 'Add to Product List'}
              </Button>
            )}
          </Space>
        </div>
        <div className="table-footer-right">
          <Button
            type="link"
            onClick={handleClearSelection}
            className="link-button"
          >
            Clear Selection
          </Button>
        </div>
      </div>
    </>
  );

  const handleAddToProductList = async (
    selectedProducts: SelectedProduct[]
  ) => {
    try {
      setIsAddingProducts(true);
      // Add products to list
      const productIds = selectedProducts.map((product) => product.uuid);
      await dispatch(addProductsToProductList(productIds));

      // Get current state for filtering/search

      // Build params object with all current filters
      let params: ListProductLibraryParams = {
        _limit: pageSize,
        _offset: (currentPage! - 1) * pageSize!,
        _order_by: 'updated_at:desc',
        status: 'approved',
      };

      // Add category filter if selected
      if (selectedCategory && selectedCategory !== 'All') {
        params.category = selectedCategory.key;
      }

      // Add other filters if present
      if (selectedFilters) {
        Object.keys(selectedFilters).forEach((filterGroup) => {
          const filterKey = filterGroup as keyof FiltersType;
          if (selectedFilters[filterKey]?.length) {
            params = {
              ...params,
              [filterGroup]: selectedFilters[filterKey],
            };
          }
        });
      }

      // Dispatch filter action with both params and search query
      await dispatch(filterProducts({ params, query: searchQuery }));

      // Clear selection
      setSelectedRowKeys([]);
    } catch (error) {
      console.error('Error adding products to list:', error);
    } finally {
      setIsAddingProducts(false);
    }
  };

  const handleOnChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ListProductLibraryModel[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setSelectedProducts(selectedRows));
  };

  const rowSelection: TableRowSelection<ListProductLibraryModel> = {
    selectedRowKeys: selectedProductRowKeys,
    onChange: handleOnChange,
    preserveSelectedRowKeys: true,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const footerContent = hasSelected ? tableFooter : undefined;

  return (
    <div className="products-table-list-wrapper">
      {header}
      <ConfigProvider
        renderEmpty={() => customizeRenderEmpty(noDataDescription)}
      >
        <Table
          className="products-table-list"
          footer={footerContent}
          scroll={{ y: tableHeight || 240 }}
          rowSelection={rowSelection}
          loading={
            fetchingProductLibraryItems ||
            isAddingProducts ||
            isRemovingProducts
          }
          rowKey={(product: ListProductLibraryModel) =>
            `${product.uuid}-row-key`
          }
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: customizeRenderEmpty(noDataDescription) }}
        />
      </ConfigProvider>
    </div>
  );
};

export default withRouter<any, any>(memo(ProductLibraryTable, propsAreEqual));
