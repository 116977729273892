import { useState } from 'react';

import { MinusOutlined } from '@ant-design/icons';
import { Card, Input, Steps } from 'antd';
import { useHistory } from 'react-router-dom';

import AuthService from '../../../api/auth';
import ReadySetLogo from '../../../assets/svgs/readyset-logo.svg';
import { isEmailValid, isPasswordValid } from '../../../util';
import Button from '../../elements/Button';
import { RoutePath } from '../AppRoot/types';
import './ForgotPasswordView.less';

const ForgotPasswordView = () => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmed, setPasswordConfirmed] = useState('');
  const emailInvalid = !isEmailValid(recoveryEmail);
  const verificationCodeInvalid = verificationCode.length !== 6;
  const passwordInvalid = !isPasswordValid(password, passwordConfirmed);

  const disableNextBtn = (): boolean => {
    switch (currentStep) {
      case 0:
        return loading || emailInvalid;
      case 1:
        return loading;
      case 2:
        return loading || verificationCodeInvalid;
      case 3:
        return loading || passwordInvalid;
      default:
        return false;
    }
  };

  const onNextBtnClick = async () => {
    switch (currentStep) {
      case 0:
        setCurrentStep(1);
        break;
      case 1:
        setLoading(true);
        await AuthService().sendPasswordResetVerificationCode(recoveryEmail);
        setLoading(false);
        setCurrentStep(2);
        break;
      case 2:
        setLoading(true);
        await AuthService().verifyResetCode({
          email: recoveryEmail,
          verificationCode,
        });
        setLoading(false);
        setCurrentStep(3);
        break;
      case 3:
        setLoading(true);
        await AuthService().resetPassword({
          email: recoveryEmail,
          password,
          password_confirmation: passwordConfirmed,
        });
        setLoading(false);
        history.push(RoutePath.LogIn);
        break;
    }
  };

  const onBackBtnClick = () => {
    if (currentStep === 0) {
      history.push(RoutePath.LogIn);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const steps = [
    {
      title: 'Step 1',
      content: (
        <>
          <p>
            To reset your password, please enter the email address associated
            with your ReadySet account.
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 55 }}
          >
            <label htmlFor="email" className="forgot-password-input-label">
              Email
            </label>
            <Input
              autoFocus
              name="email"
              placeholder="Email"
              value={recoveryEmail}
              onChange={(e) => setRecoveryEmail(e.target.value)}
              status={emailInvalid ? 'error' : ''}
            />
          </div>
        </>
      ),
    },
    {
      title: 'Step 2',
      content: (
        <>
          <p style={{ marginBottom: 90 }}>
            To verify your account we’ll send you a verification code to your
            email. Email verification code to: <strong>{recoveryEmail}</strong>.
          </p>
        </>
      ),
    },
    {
      title: 'Step 3',
      content: (
        <>
          <p>
            A verification code has been sent by email to{' '}
            <strong>{recoveryEmail}</strong>. Enter the code to verify the
            account ownership.
          </p>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 16 }}
          >
            <label
              htmlFor="verification-code"
              className="forgot-password-input-label"
            >
              Verification Cdoe
            </label>
            <Input
              autoFocus
              name="verification-code"
              placeholder="xxxxxx"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              status={verificationCodeInvalid ? 'error' : ''}
            />
          </div>
          <p>This code will expire in 10 minutes.</p>
        </>
      ),
    },
    {
      title: 'Step 4',
      content: (
        <>
          <p>Your password must contain at least 3 of the following:</p>
          <div className="forgot-password-requirements">
            <span
              style={{
                color:
                  password === passwordConfirmed && password.length >= 8
                    ? '#40C976'
                    : '',
              }}
            >
              <MinusOutlined /> 8 characters (required)
            </span>
            <span
              style={{
                color:
                  password === passwordConfirmed && /(?=.*[A-Z])/.test(password)
                    ? '#40C976'
                    : '',
              }}
            >
              <MinusOutlined /> One uppercase character
            </span>
            <span
              style={{
                color:
                  password === passwordConfirmed &&
                  /(?=.*[!@#$%^&*])/.test(password)
                    ? '#40C976'
                    : '',
              }}
            >
              <MinusOutlined /> One special character
            </span>
            <span
              style={{
                color:
                  password === passwordConfirmed && /(?=.*[a-z])/.test(password)
                    ? '#40C976'
                    : '',
              }}
            >
              <MinusOutlined /> One lowercase character
            </span>
            <span
              style={{
                color:
                  password === passwordConfirmed && /(?=.*\d)/.test(password)
                    ? '#40C976'
                    : '',
              }}
            >
              <MinusOutlined /> One number
            </span>
          </div>
          <div
            className="forgot-password-input-wrapper"
            style={{ marginBottom: 24 }}
          >
            <label htmlFor="password" className="forgot-password-input-label">
              New Password
            </label>
            <Input
              autoFocus
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label
              htmlFor="confirm-password"
              className="forgot-password-input-label"
              style={{ marginTop: 16 }}
            >
              Confirm New Password
            </label>
            <Input
              name="confirm-password"
              type="password"
              value={passwordConfirmed}
              onChange={(e) => setPasswordConfirmed(e.target.value)}
            />
          </div>
        </>
      ),
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: '' }));

  return (
    <div className="forgot-password-view">
      <Card style={{ width: '450px', marginBottom: '72px' }}>
        <div className="forgot-password-card-content-wrapper">
          <img
            src={ReadySetLogo}
            alt="ReadySet logo"
            className="readyset-logo"
          />
          <h3 className="forgot-password-steps-header">
            {currentStep < steps.length - 1
              ? 'Password Assistance'
              : 'Create new password'}
          </h3>
          <Steps
            current={currentStep}
            items={items}
            className="forgot-password-steps"
          />
          <div className="forgot-password-step-wrapper">
            {steps[currentStep].content}
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <Button onClick={onBackBtnClick} className="forgot-password-button">
              Back
            </Button>
            <Button
              disabled={disableNextBtn()}
              type="primary"
              className="forgot-password-button"
              onClick={onNextBtnClick}
            >
              {currentStep < steps.length - 1
                ? 'Continue'
                : 'Continue to login'}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ForgotPasswordView;
