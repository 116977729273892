import { BASE_API_URL } from '../../config';
import { ApiResponse, del, get, ListApiResponse, post } from '../base';
import {
  AddProductItemToListResponse,
  ClearProductListResponse,
  CountProductItemsInListResponse,
  ProductBrandResponse,
  ProductListResponse,
  ProductResponse,
  RemoveProductItemFromListResponse,
} from './reqres';

export default function ProductListService() {
  return Object.freeze({
    listProductList(query?: string): Promise<ProductListResponse> {
      return get(
        `${BASE_API_URL()}/product-list/${query}`
      ) as Promise<ListApiResponse>;
    },
    searchProductList(query: string): Promise<ProductListResponse> {
      return get(
        `${BASE_API_URL()}/product-list/${query}`
      ) as Promise<ListApiResponse>;
    },
    bulkAddProductItemsToList(
      productIds: string[]
    ): Promise<AddProductItemToListResponse> {
      return post(`${BASE_API_URL()}/list/add`, productIds);
    },
    addProductItemToList(
      productId: string
    ): Promise<AddProductItemToListResponse> {
      return get(`${BASE_API_URL()}/list/${productId}/add`);
    },
    removeProductItemFromList(
      productId: string
    ): Promise<RemoveProductItemFromListResponse> {
      return get(`${BASE_API_URL()}/list/${productId}/remove`);
    },
    clearProductList(): Promise<ClearProductListResponse> {
      return get(`${BASE_API_URL()}/list/clear`);
    },
    countProductItemsInList(): Promise<CountProductItemsInListResponse> {
      return get(`${BASE_API_URL()}/list/count`);
    },
    getProduct(productId: string): Promise<ProductResponse> {
      return get(`${BASE_API_URL()}/library/${productId}`);
    },
    deleteProduct(productId: string): Promise<ApiResponse> {
      return del(`${BASE_API_URL()}/library/${productId}`);
    },
    getBrands(): Promise<ProductBrandResponse> {
      return get(`${BASE_API_URL()}/brand/`) as Promise<ProductBrandResponse>;
    },
    bulkRemoveProductItemsFromList(
      productIds: string[]
    ): Promise<RemoveProductItemFromListResponse> {
      return post(`${BASE_API_URL()}/list/remove`, productIds);
    },
  });
}
