import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store';

import { CheckOutlined, LineOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Space, Typography } from 'antd';

import {
  changePassword,
  getSavingUser,
} from '../../../../store/features/users/usersSlice';
import { getMe, getMePermissions, UserPermissions } from '../../../../util';
import './ChangePasswordForm.less';

const { Text } = Typography;

interface PasswordRequirement {
  text: string;
  fulfilled: boolean;
}

interface PasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ChangePasswordFormProps {
  userId?: string;
}

const ChangePasswordForm = ({ userId }: ChangePasswordFormProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const savingUser = useSelector(getSavingUser);

  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordRequirement[]
  >([
    { text: 'Minimum 10 characters', fulfilled: false },
    { text: 'Special characters (!, @, #, $, %, ^, &, *)', fulfilled: false },
    { text: 'Numbers (0-9)', fulfilled: false },
    { text: 'Uppercase letters (A-Z)', fulfilled: false },
    { text: 'Lowercase letters (a-z)', fulfilled: false },
  ]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fulfilledRequirements = passwordRequirements.map((req) =>
      req.text === 'Minimum 10 characters'
        ? { ...req, fulfilled: value.length >= 10 }
        : req.text === 'Special characters (!, @, #, $, %, ^, &, *)'
        ? { ...req, fulfilled: /[!@#$%^&*]/.test(value) }
        : req.text === 'Numbers (0-9)'
        ? { ...req, fulfilled: /\d/.test(value) }
        : req.text === 'Uppercase letters (A-Z)'
        ? { ...req, fulfilled: /[A-Z]/.test(value) }
        : req.text === 'Lowercase letters (a-z)'
        ? { ...req, fulfilled: /[a-z]/.test(value) }
        : req
    );
    setPasswordRequirements(fulfilledRequirements);
  };

  const [form] = Form.useForm();

  const handleSubmit = async (values: PasswordFormValues) => {
    // Get current user and permissions
    const currentUser = getMe();
    const userPermissions = getMePermissions();
    const targetUserId = userId || currentUser.uuid;

    const canChangePassword =
      targetUserId === currentUser.uuid ||
      userPermissions.includes(UserPermissions.UsersEdit);

    if (!canChangePassword) {
      message.error('You do not have permission to change this password.');
      return;
    }

    if (values.newPassword.length < 10) {
      message.error('Password must be at least 10 characters long.');
      return;
    }

    const characterTypeRequirements = passwordRequirements.slice(1);
    const numFulfilledCharacterTypes = characterTypeRequirements.filter(
      (req) => req.fulfilled
    ).length;

    if (numFulfilledCharacterTypes < 3) {
      message.error(
        'Password must contain at least 3 of the following: uppercase letters, lowercase letters, numbers, or special characters.'
      );
      return;
    }

    if (values.newPassword !== values.confirmNewPassword) {
      message.error('New password and confirm new password do not match.');
      return;
    }

    try {
      const result = await dispatch(
        changePassword({
          apiUser: getMe().email,
          apiPass: values.currentPassword,
          newPassword: values.newPassword,
        })
      ).unwrap();

      if (result) {
        message.success('Password changed successfully');
        form.resetFields();
        setPasswordRequirements(
          passwordRequirements.map((req) => ({ ...req, fulfilled: false }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Text>Password requirements:</Text>
      <Text>• Must be at least 10 characters</Text>
      <Text>• Must contain at least 3 of the following:</Text>
      <div>
        <ul className="password-requirements-list">
          {passwordRequirements.map((req, index) => (
            <li
              key={index}
              style={{ color: req.fulfilled ? '#40C976' : 'inherit' }}
            >
              {req.fulfilled ? (
                <CheckOutlined style={{ color: '#40C976', marginRight: 8 }} />
              ) : (
                <LineOutlined style={{ marginRight: 8 }} />
              )}
              {req.text}
            </li>
          ))}
        </ul>
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[{ required: true, message: 'Current password is required.' }]}
        >
          <Input.Password
            data-cy="change-password-form-input"
            placeholder="Current Password"
          />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[{ required: true, message: 'New password is required.' }]}
        >
          <Input.Password
            data-cy="change-password-form-input"
            placeholder="New Password"
            onChange={handlePasswordChange}
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          rules={[
            { required: true, message: 'Confirm new password is required.' },
          ]}
        >
          <Input.Password
            data-cy="change-password-form-input"
            placeholder="Confirm New Password"
            onChange={handlePasswordChange}
          />
        </Form.Item>

        <Form.Item>
          <Space style={{ float: 'right' }}>
            <Button
              data-cy="change-password-form-submit-btn"
              htmlType="submit"
              type="primary"
              loading={savingUser}
              disabled={savingUser}
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePasswordForm;
