import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiError, handleError } from '../../../api/base';
import { WBMLProduct, WBMLSearchParams } from '../../../api/wbml/reqres';
import WBMLService, {
  MetadataType,
  WBMLCategory,
  WBMLMetadata,
} from '../../../api/wbml/service';

interface WBMLError {
  code: number | null;
  message: string | null;
}

interface WBMLPagination {
  total: number;
  page: number;
  limit: number;
  total_pages: number;
}

export interface WBMLState {
  products: WBMLProduct[];
  categories: WBMLCategory[];
  metadata: {
    brands?: WBMLMetadata[];
    manufacturers?: WBMLMetadata[];
    parentCompanies?: WBMLMetadata[];
    markets?: WBMLMetadata[];
    formats?: WBMLMetadata[];
    tags?: WBMLMetadata[];
    [key: string]: WBMLMetadata[] | undefined;
  };
  loading: boolean;
  error: WBMLError;
  searchQuery: string;
  pagination: WBMLPagination | null;
  metadataLoading: boolean;
  categoriesLoading: boolean;
  isToolbarVisible: boolean;
}

const initialState: WBMLState = {
  products: [],
  categories: [],
  metadata: {},
  loading: false,
  error: { code: null, message: null },
  searchQuery: '',
  pagination: null,
  metadataLoading: false,
  categoriesLoading: false,
  isToolbarVisible: true,
};

export const searchWBMLProducts = createAsyncThunk(
  'wbml/searchProducts',
  async (
    {
      params,
      page,
      pageSize,
    }: {
      params: WBMLSearchParams;
      page: number;
      pageSize: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await WBMLService().search({
        ...params,
        page: page.toString(),
        limit: pageSize.toString(),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWBMLCategories = createAsyncThunk(
  'wbml/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await WBMLService().getCategories();
      return response.data.categories;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWBMLMetadataByType = createAsyncThunk(
  'wbml/fetchMetadataByType',
  async (type: MetadataType, { rejectWithValue }) => {
    try {
      const response = await WBMLService().getMetadataByType(type);
      return { type, data: response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAllWBMLMetadata = createAsyncThunk(
  'wbml/fetchAllWBMLMetadata',
  async (_, { rejectWithValue }) => {
    try {
      const response = await WBMLService().getMetadata();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const wbmlSlice = createSlice({
  name: 'wbml',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setToolbarVisibility: (state, action) => {
      state.isToolbarVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchWBMLProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchWBMLProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.pagination = action.payload.pagination;
      })
      .addCase(searchWBMLProducts.rejected, (state, action) => {
        state.loading = false;
        if (action.payload && (action.payload as ApiError).error) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        }
      })
      .addCase(fetchWBMLCategories.pending, (state) => {
        state.categoriesLoading = true;
      })
      .addCase(fetchWBMLCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.categoriesLoading = false;
      })
      .addCase(fetchWBMLCategories.rejected, (state, action) => {
        state.categoriesLoading = false;
        if (action.payload && (action.payload as ApiError).error) {
          state.error = (action.payload as ApiError).error;
          handleError(action.payload as ApiError);
        }
      })
      .addCase(fetchWBMLMetadataByType.pending, (state) => {
        state.metadataLoading = true;
      })
      .addCase(fetchWBMLMetadataByType.fulfilled, (state, action) => {
        state.metadataLoading = false;
        const { type, data } = action.payload;
        state.metadata[type] = data;
      })
      .addCase(fetchWBMLMetadataByType.rejected, (state) => {
        state.metadataLoading = false;
      })
      .addCase(fetchAllWBMLMetadata.pending, (state) => {
        state.metadataLoading = true;
      })
      .addCase(fetchAllWBMLMetadata.fulfilled, (state, action) => {
        state.metadataLoading = false;
        state.metadata = action.payload;
      })
      .addCase(fetchAllWBMLMetadata.rejected, (state) => {
        state.metadataLoading = false;
      });
  },
});

export const { setSearchQuery, setToolbarVisibility } = wbmlSlice.actions;

export default wbmlSlice.reducer;
